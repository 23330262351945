<template>
  <div>
    <a-modal v-model="visible" title="新建产品" width="680px" class="proModal">
      <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
        <div class="line-2">
          <a-form-model-item label="产品名称" prop="product_name">
            <a-input v-model="form.product_name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="产品编号" prop="product_code">
            <a-input v-model="form.product_code"></a-input>
          </a-form-model-item>
          <a-form-model-item label="公司名称" prop="company_oid">
            <a-select :disabled="lockCompany" v-model="form.company_oid" @change="companyChange" showSearch optionFilterProp="children" style="width: 100%">
              <a-select-option v-for="item in companyList" :key="item.oid" :value="item.oid" :options="item">
                {{item.company_name}}
              </a-select-option>
            </a-select>
<!--            <auto-input-->
<!--                url="/company/retrieve"-->
<!--                style="width: 100%"-->
<!--                value-key="oid"-->
<!--                label-key="company_name"-->
<!--                v-model="form.company_oid"-->
<!--                :label.sync="form.company_name"-->
<!--            />-->
          </a-form-model-item>
          <a-form-model-item label="供应商编号" prop="company_no">
            <auto-input
              :disabled="lockCompany"
              url="/company/retrieve"
              value-key="company_no"
              label-key="company_no"
              v-model="form.company_no"
              :label.sync="form.company_no"
            />
          </a-form-model-item>
          <a-form-model-item label="产品类别" prop="product_category">
            <a-tree-select
                v-model="form.product_category"
                style="width: 100%"
                showSearch
                treeNodeFilterProp="title"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                placeholder=""
                tree-default-expand-all
                :tree-data="productCategoryList"
                :replaceFields="{children:'children', title:'cat_name', key:'cat_code', value: 'cat_code' }"
                :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body;}"
              >
              <span slot="title" slot-scope="{ key, value }" style="color: #08c">
                {{ value }}
              </span>
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="产品类型" prop="product_type">
            <a-select v-model="form.product_type" showSearch optionFilterProp="children" style="width: 100%">
              <a-select-option v-for="item in cat.product_type" :key="item.oid" :value="item.cat_code">
                {{item.cat_name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="产品版本" prop="product_series">
            <a-input v-model="form.product_series"></a-input>
          </a-form-model-item>
          <a-form-model-item label="原厂家" prop="product_company">
            <a-select v-model="form.product_company" showSearch optionFilterProp="children" style="width: 100%">
              <a-select-option v-for="item in companyList" :key="item.oid" :value="item.oid">
                {{item.company_name}}
              </a-select-option>
            </a-select>
            <!--            <auto-input-->
            <!--                url="/company/retrieve"-->
            <!--                style="width: 100%"-->
            <!--                value-key="oid"-->
            <!--                label-key="company_name"-->
            <!--                v-model="form.product_company"-->
            <!--                :label.sync="form.product_company_name"-->
            <!--            />-->
          </a-form-model-item>
          <a-form-model-item label="品牌" prop="band">
            <a-input v-model="form.band"></a-input>
          </a-form-model-item>
          <a-form-model-item label="单位" prop="unit">
            <a-input v-model="form.unit"></a-input>
          </a-form-model-item>
<!--          <a-form-model-item label="负责人" prop="product_admin">-->
<!--            <auto-input-->
<!--                url="/cpyaccount/retrieve"-->
<!--                style="width: 100%"-->
<!--                value-key="oid"-->
<!--                label-key="name"-->
<!--                v-model="form.product_admin"-->
<!--                :label.sync="form.product_admin_name"-->
<!--            />-->
<!--          </a-form-model-item>-->
        </div>
        <a-form-model-item label="规格型号" prop="tech_info">
          <a-textarea :rows="2" v-model="form.tech_info"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="描述" prop="product_intro">
          <a-textarea :rows="4" v-model="form.product_intro"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="产品附件" prop="product_intro">
          <attachment-upload :source-oid="form.oid" @setOid="oid => form.oid = oid" source-type="2"></attachment-upload>
        </a-form-model-item>
      </a-form-model>
      <template #footer>
        <div class="modal-footer" style="display: flex; align-items: center">
          <a-checkbox v-model="next" style="margin-right: auto;">
            继续创建下一个
          </a-checkbox>
          <a-space>
            <a-button @click="visible = false">
              取消
            </a-button>
            <a-button type="primary" @click="submit">
              确定
            </a-button>
          </a-space>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import catMixin from "@/utils/mixins/catMixin";
import autoInput from "@/components/autoInput.vue";
import attachmentUpload from "@/components/attachmentUpload.vue";
import {fetch} from "@/utils/request";

export default {
  name: "productModal",
  props: {
    catCode: {
      type: String,
      default: ''
    }
  },
  mixins: [catMixin],
  data() {
    return {
      form: {
        product_name: '',
        product_code: '',
        company_name: '',
        company_no: '',
        product_category: '',
        product_type: '',
        product_series: '',
        tech_info: '',
        product_company: '',
        product_admin: '',
        oid: ''
      },
      backup: {},
      next: false,
      rules: {
        product_name: [
          {required: true, message: "请填写产品名称", trigger: 'blur'},
        ],
        product_code: [
          {required: true, message: "请填写产品编号", trigger: 'blur'},
        ],
        company_oid: [
          {required: true, message: "请填写公司名称"},
        ],
        product_category: [
          {required: true, message: "请选择产品类别"},
        ]
      },
      visible: false,
      cat: {
        product_type: [],
        product_category: []
      },
      companyList: [],
      productCategoryList: [],
      product_cat: '',
      lockCompany: false,
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {autoInput, attachmentUpload},
  watch: {
    catCode: {
      handler(val) {
        console.log(val);
        if (val) this.product_cat = val
      }
    }
  },
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
    this.getCompanyList()
    this.getProductCategoryList()
  },
  methods: {
    open(lockCompany = false) {
      let company_oid = ""
      let company_no = ""
      this.lockCompany = lockCompany
      if (lockCompany) {
        let company_info = JSON.parse(localStorage.getItem('company_info'))
        company_oid = company_info.company_oid
        company_no = company_info.company_no
      }
      this.visible = true;
      this.form = {
        ...this.backup,
        company_oid,
        company_no,
        product_category: this.product_cat // 默认选中的类目
      }
    },
    handleOk() {
      this.visible = false;
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log(valid, this.form);
          let {account_oid} = JSON.parse(localStorage.getItem('company_info'))
          this.form.product_admin = account_oid
          fetch('post','/pro/create', {
            ...this.form
          }).then((res)=>{
            if (res.status === 0) {
              this.$message.success('新建成功')
              if (this.next) {
                this.nextCreate()
              } else {
                this.visible = false
              }
              this.$emit('reload')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    getCompanyList() {
      fetch('post','/company/retrieve', { keywords: '', page_size: 999}).then((res)=>{
        if (res.status === 0) {
          this.companyList = res.data.datas
          console.log(res.data.datas);
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getProductCategoryList() {
      fetch('post','/cpy/cat/getall', { keywords: '', cat_type: 'product_category', page_size: 999}).then((res)=>{
        if (res.status === 0) {
          console.log(res.data);
          this.productCategoryList = res.data.categories
        } else {
          this.$message.error(res.message)
        }
      })
    },
    nextCreate() {
      this.form = {
        ...this.backup
      }
      this.visible = true
    },
    companyChange(value, option) {
      console.log(value, option);
      let item = option.data.attrs.options
      this.form.company_no = item.company_no
    }
  }
}
</script>

<style lang="less" scoped>

</style>