<template>
  <div class="app-main-content">
    <card title="产品类别" class="stage-left" v-if="!isSupplier">
      <template v-slot:title>
        <div class="edp-card-head-left-title" @click="toTop">产品类别</div>
      </template>
      <edp-tree ref="edpTree" :data="cat.product_category" @change="clickTree"
                row-key="oid" :flag="true" @fromSun="getCatChildren">
        <template v-slot="{ row, open, fun }">
          <div class="tree-row">
            <div class="tree-row-info">
              <img :src='$toUrl("folder_open.png")' class="dir" alt="">
              {{ row.cat_name }}
            </div>
            <div class="tree-row-dropdown" @click.stop>
              <a-dropdown>
                <a style="color: #08f;" class="ant-dropdown-link" @click="e => e.preventDefault()">
                  <a-icon type="ellipsis" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" @click="addCat(row, false, open, fun)">新增</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="addCat(row, true)">修改</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="delCat(row)">删除</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </template>
      </edp-tree>
    </card>
    <div style="flex: 1;height: 100%;overflow: hidden;box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.5)">
      <card body-class="overflow-hidden page-card-body" style="height: 100%;" title="产品管理">
        <template #tools>
          <div class="left">
            <a-button type="primary" @click="openProModal">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              新增产品
            </a-button>
          </div>
          <div class="right">
            <div class="item">
              <span class="label">排序</span>
              <a-select @change="getProList" class="sort-select" placeholder="请选择"
                        v-model="orderby">
                <a-select-option :value="item.value" v-for="(item, index) in orderbyList" :key="index">{{
                    item.name
                  }}
                </a-select-option>
              </a-select>
            </div>
            <div class="item">
              <span class="label">搜索</span>
              <a-input class="search-input" v-model="search" placeholder="请输入产品名称">
                <a-icon slot="prefix" type="search" />
              </a-input>
            </div>
            <div class="item">
              <a-button class="search" @click="getProList">查询</a-button>
            </div>
            <div class="item">
              <a-dropdown>
                <img class="more" :src="$toUrl('more.png')">
                <a-menu slot="overlay" @click="selectActionMenu">
                  <a-menu-item key="download">模版</a-menu-item>
                  <a-menu-item key="import">导入</a-menu-item>
                  <a-menu-item key="export">导出</a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </template>
        <div class="body-flex">
          <div class="body-left-table scrollY">
            <a-table style="min-width: 900px; box-sizing: border-box" :data-source="proList" :customRow="customRow"
                     rowKey="oid" :pagination="false">
              <a-table-column title="产品编号" data-index="product_code" width="150px"></a-table-column>
              <a-table-column title="产品名称" data-index="product_name" width="250px">
                <template slot-scope="scope, row">
                  <div class="edp-title" style="display: flex;align-items: center;">
                    <span class="name">{{ row.product_name }}</span>
                    <a-tooltip>
                      <template slot="title">修改</template>
                      <i class="iconfont icon-wenbenshuru hidden" style="margin-top: 3px"
                         @click="showTaskDetailDialog(row)"></i>
                    </a-tooltip>
                  </div>
                </template>
              </a-table-column>
              <!--              <a-table-column title="规格型号" data-index="tech_info"></a-table-column>-->
              <a-table-column title="产品类型" data-index="product_type_name" width="130px"></a-table-column>
              <!--              <a-table-column title="原厂家" data-index="product_company"></a-table-column>-->
              <a-table-column title="供应商" data-index="company_name"></a-table-column>
              <a-table-column width="150px" title="供应商编号" data-index="company_no"></a-table-column>
              <a-table-column title="产品类别" data-index="product_category_name"></a-table-column>
            </a-table>
          </div>
          <product-detail ref="productDetail" @delete="deleteProduct" :prooid="curProOid" @reload="getProList" prj-pro
                          :activeTab.sync="curTabsIndex">
            <template #bom>
              <div class="tabs-bottom-content tabs-content bom-detail" style="padding-left:10px">
                <div class="detail">
                  <a-table :data-source="proBomList" :expandIcon="$customExpandIcon" rowKey="oid" :pagination="false"
                           :expanded-row-keys.sync="expandedRowKeys">
                    <a-table-column title="名称">
                      <template v-slot:default="scope, row">
                        <div v-if="row.edit" class="edit-name">
                          <auto-input
                              url="/pro/retrieve"
                              style="width: 100%"
                              value-key="oid"
                              label-key="product_name"
                              v-model="row.node_name"
                              :label.sync="row.node_name"
                              :query="{product_category: curProCatCode, fatheroid: row.fatheroid}"
                              :idx="row"
                              @change="sensingPersonnel"
                          />
                        </div>
                        <div style="display: inline-block; cursor: pointer; color: #1890ff;" @click="toInfo(row)"
                             v-else>
                          {{ row.node_name }}
                        </div>
                      </template>
                    </a-table-column>
                    <a-table-column title="BOM数" width="90px">
                      <template v-slot:default="row">
                        <div class="edp-title">
                          <template v-if="row.level !== 1">
                            <a-input style="width: 100%;" v-if="row.edit" v-model="row.require_count"></a-input>
                            <div style="display: inline-block" v-else>{{ row.require_count }}</div>
                          </template>
                        </div>
                      </template>
                    </a-table-column>
                    <a-table-column title="规格型号" width="120px">
                      <template v-slot:default="row">
                        <div class="edp-title">
                          <a-input style="width: 100%" v-if="row.edit" v-model="row.tech_info"></a-input>
                          <div style="display: inline-block" v-else>{{ row.tech_info }}</div>
                        </div>
                      </template>
                    </a-table-column>
                    <a-table-column title="操作" width="85px">
                      <template v-slot:default="scope, row">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>新增子BOM</span>
                          </template>
                          <a-icon type="plus" @click="createBom(row)" style="margin-right: 5px" />
                        </a-tooltip>
                        <a-tooltip placement="top" v-if="!row.edit">
                          <template slot="title">
                            <span>编辑</span>
                          </template>
                          <a-icon type="edit" @click="editBom(row)" style="margin-right: 5px" />
                        </a-tooltip>
                        <a-tooltip placement="top" v-else>
                          <template slot="title">
                            <span>保存</span>
                          </template>
                          <a-icon type="save" @click="saveBom(row)" style="margin-right: 5px" />
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>删除</span>
                          </template>
                          <a-icon type="delete" @click="delBom(row)" />
                        </a-tooltip>
                      </template>
                    </a-table-column>
                  </a-table>
                </div>
              </div>
            </template>
            <template #bizlog>
              <bizlog ref="bizlog" :source-oid="curProOid"></bizlog>
            </template>
          </product-detail>
        </div>
      </card>
    </div>
    <productModal ref="proModal" @reload="getProList" :cat-code="curProCatCode"></productModal>
    <a-modal v-model="visible" :title="form.title" width="680px" class="uploadModal">
      <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
        <div class="line-2">
          <a-form-model-item label="类别名称" prop="cat_name">
            <a-input v-model="form.cat_name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="编号" prop="cat_code">
            <a-input v-model="form.cat_code"></a-input>
          </a-form-model-item>
        </div>
      </a-form-model>
      <template #footer>
        <div class="modal-footer">
          <a-space>
            <a-button @click="visible = false">
              取消
            </a-button>
            <a-button type="primary" @click="submit">
              确定
            </a-button>
          </a-space>
        </div>
      </template>
    </a-modal>
    <import-file url="/pro/import" ref="importData" @response="uploadResponse"></import-file>
    <task-detail-dialog ref="taskDetailDialog" :show-dialog.sync="showTaskDialog" :prooid="projectOid"
                        @getPageData="getPageData" />
  </div>
</template>

<script>
import EdpTree from "@/components/edpTree.vue";
import productModal from "@/components/productModal.vue";
import autoInput from "@/components/autoInput.vue";
import appsettings from '@/utils/appsettings'
import bizlog from "@/components/bizlog.vue";
import {fetch} from "@/utils/request";
import {queryChildren, genGuid, getTemplate} from "@/utils/helpFunc";
import ImportFile from "@/components/importFile.vue";
import ProductDetail from "@/components/productDetail.vue";
import _ from "lodash";
import TaskDetailDialog from "@/view/product/components/taskDetailDialog.vue";

export default {
  name: "projectStage",
  components: { TaskDetailDialog, ProductDetail, ImportFile, EdpTree, productModal, autoInput, bizlog },
  data() {
    return {
      appsettings: appsettings,
      active: null,
      search: '',
      cat: {
        product_category: []
      },
      curProCatCode: '',
      proList: [],
      proDetail: {},
      proBomList: [],
      orderby: 'create_time desc',
      orderbyList: [
        { name: '创建时间传', value: 'create_time desc' },
        { name: '更新时间传', value: 'update_time desc' },
        { name: '版本传', value: 'product_series desc' },
        { name: '类型传', value: 'product_type desc' },
      ],
      curTabsIndex: '1',
      tabActive: "1",
      expandedRowKeys: [],
      visible: false,
      form: {
        title: '新增产品类目',
        cat_name: '',
        cat_code: '',
        cat_type: 'product_category',
        field_type: '0',
        level: '',
        routemap: ''
      },
      backup: {},
      editCat: {},
      rules: {
        cat_code: [{ required: true, message: "请输入编号", trigger: 'change' }],
        cat_name: [{ required: true, message: "请输入名称", trigger: 'change' }]
      },
      curProOid: "",
      hasDetail: false,
      editFolder: {},
      addOpen: function () {
      },
      // 任务详情
      showTaskDialog: false,
      projectOid: '',
    }
  },
  computed: {
    isSupplier() {
      let roles = JSON.parse(localStorage.getItem('company_info')).Roles
      return !!roles.find(item => item.role_code === 'supplier')
    }
  },
  watch: {
    // 'cat.product_category' : {
    //   deep: true,
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.getProList()
    //     }
    //   }
    // }
  },
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
  },
  mounted() {
    this.getCatList('product_category', 1)
    this.getProList()
  },
  methods: {
    // 打开任务详情
    showTaskDetailDialog(row) {
      this.projectOid = row.oid
      this.showTaskDialog = true
      this.$refs.taskDetailDialog.getPageData(row.oid)
    },
    // 重新获取数据
    getPageData() {
      this.getProList()
      this.$refs.bizlog.getBizLogList()
      this.$refs.productDetail.getDetail()
    },
    deleteProduct() {
      fetch('post', '/pro/delete', {
        oid: this.curProOid
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('删除成功！')
          this.getProList()
          this.curProOid = ""
        } else {
          this.$message.error(res.message)
        }
      })
    },
    uploadResponse(res) {
      if (res.status === 0) {
        this.getProList()
      } else {
        this.$message.error(res.message)
      }
    },
    selectActionMenu({ key }) {
      if (key === "import") {
        this.$refs.importData.upload()
      } else if (key === "export") {
        // console.log(this.$toUrl(`/pro/export?oids=${this.proList.map(item => item.oid).join(',')}`, true, "api"));
        if (this.proList.length > 0) {
          window.open(this.$toUrl(`/pro/export?oids=${this.proList.map(item => item.oid).join(',')}`, true, "api"))
        } else {
          this.$message.error('暂无产品')
        }
      } else if (key === "download") {
        getTemplate('/attachment/model', '产品导入模版.xlsx')
      }
    },
    getCatList(cat_type, level) {
      fetch("post", "/cpy/cat/retrieve", { cat_type, level }).then(res => {
        if (res.status === 0) {
          console.log(res.data);
          this.cat.product_category = res.data.datas
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getCatChildren(row) {
      console.log(row);
      let { cat_type, level, routemap, oid } = row
      fetch('post', '/cpy/cat/retrieve', {
        cat_type,
        level: level - 0 + 1,
        routemap: routemap + '/' + oid
      }).then((res) => {
        if (res.status === 0) {
          console.log('子级文件夹', res.data);
          if (res.data.datas.length > 0) {
            this.$set(row, 'children', res.data.datas)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getProList() {
      let url = "/pro/retrieve"
      if (this.isSupplier) {
        url = "/pro/retrieve/bysupplier"
      }
      return fetch('post', url, {
        keywords: this.search,
        orderby: this.orderby,
        product_category: this.curProCatCode,
        page_size: 999
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data.datas
          console.log(data);
          this.proList = data
          if (!this.curProOid) {
            if (this.proList.length > 0) {
              this.curProOid = this.proList[0].oid
              this.proDetail = {
                product_code: this.proList[0].product_code
              }
              this.getProBom(this.proList[0].oid)
              this.hasDetail = true
            } else {
              this.hasDetail = false
            }
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleBom(list, level = 1) {
      return list.map(item => {
        return {
          ...item,
          edit: false,
          children: item.children?.length ? this.handleBom(item.children, level + 1) : undefined,
          level,
        }
      })
    },
    getProBom(oid) {
      fetch('post', '/pro/bom', {
        oid,
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          console.log(data);
          this.proBomList = this.handleBom(data)
          queryChildren(this.proBomList)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleTabClick(index) {
      console.log(index);
      this.active = index
    },
    clickTree(row) {
      console.log(row);
      this.curProCatCode = row.cat_code
      this.getProList()
    },
    customRow(row) {
      return {
        on: {
          click: () => {
            this.curProOid = row.oid
            console.log(row);
            // this.getProDetail(row.oid)
            this.proDetail = {
              product_code: row.product_code
            }
            this.getProBom(row.oid)
          }
        }
      }
    },
    delPro(row) {
      const that = this
      this.$confirm({
        title: `确定${row.product_name}删除?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          fetch('post', '/pro/delete', {
            oid: row.oid,
          }).then((res) => {
            if (res.status === 0) {
              that.$message.success('删除成功！')
              that.getProList()
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    },
    openProModal() {
      this.$refs.proModal.open(this.isSupplier)
    },
    createBom(row) {
      console.log(row);
      let data = []
      let { route_map } = row
      if (Object.keys(row).length > 0) {
        if (row.end_flag === '0') {
          row.end_flag = '1'
          row.children = []
        }
        data = row.children
        // 往展开数组里面放oid
        this.expandedRowKeys.push(row.oid)
      } else {
        data = this.proBomList
      }
      console.log("pushlevel", (row.level || 0) + 1)
      data.push({
        node_name: '',
        require_count: '',
        product_admin: '',
        product_admin_name: '',
        children: undefined,
        end_flag: '0',
        flag: true,
        fatheroid: row.oid,
        oid: genGuid(),
        edit: true,
        tech_info: '',
        route_map,
        level: (row.level || 0) + 1
      })
    },
    // 智能感知带出负责人
    sensingPersonnel(value, item, data) {
      console.log(value, item, data);
      this.$set(data, 'prooid', item.oid)
      this.$set(data, 'node_oid', item.oid)
      this.$set(data, 'tech_info', item.tech_info)
      this.$set(data, 'product_admin', item.product_admin)
      this.$set(data, 'product_admin_name', item.product_admin_name)
    },
    editBom(row) {
      this.$set(row, 'edit', true)
    },
    saveBom(row) {
      console.log(row);
      let url = row.flag ? '/pro/bom/save' : '/pro/bom/updatebom'
      let data = {
        ...row,
        product_code: this.proDetail.product_code,
        product_name: row.node_name
      }
      fetch('post', url, data).then((res) => {
        if (res.status === 0) {
          this.$message.success('保存成功！')
          this.$set(row, 'edit', false)
          this.$set(row, 'flag', false)
          this.$set(row, 'route_map', res.data.route_map)
          this.$set(row, 'oid', res.data.oid)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    delBom(row) {
      console.log(row);
      if (row.flag) {
        this.queryTree(this.proBomList, row.oid, (datas, item, index) => {
          this.$delete(datas, index)
        })
      } else {
        fetch('post', '/pro/bom/delete', {
          oid: row.oid
        }).then((res) => {
          if (res.status === 0) {
            this.queryTree(this.proBomList, row.oid, (datas, item, index) => {
              this.$delete(datas, index)
            })
            this.$message.success('删除成功！')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    queryTree(datas, oid, cb) { // 递归循环查树结构元素
      datas.forEach((item, index) => {
        if (item.oid === oid) {
          cb(datas, item, index)
          return;
        }
        if (item.children) {  //存在子节点就递归
          this.queryTree(item.children, oid, cb);
        }
      })
    },
    submit() {
      let url = this.form.title === '编辑产品类目' ? '/cpy/cat/update' : '/cpy/cat/create'
      fetch('post', url, this.form).then((res) => {
        if (res.status === 0) {
          this.visible = false
          this.queryTree(this.cat.product_category, res.data.oid, (datas, item) => {
            this.$set(item, 'cat_name', res.data.cat_name)
          })
          if (this.form.title === '新增产品类目') this.showChild(res.data)
          this.$message.success(this.form.title + '成功！')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    addCat(row, edit, open, fun) {
      // console.log(typeof fun);
      // console.log(open);
      this.form = {
        ...this.backup,
        level: row.level - 0 + 1,
        routemap: row.routemap + '/' + row.oid,
        title: edit ? '编辑产品类目' : '新增产品类目',
        cat_name: edit ? row.cat_name : '',
        cat_code: edit ? row.cat_code : '',
        oid: edit ? row.oid : ''
      }
      if (typeof fun === 'function') this.addOpen = fun
      this.editFolder = Object.assign(row, { open: open })
      this.visible = true
    },
    showChild(row) {
      console.log(this.editFolder);
      console.log(row);
      if (!this.editFolder.children) this.editFolder.children = []
      this.editFolder.children.push(row)
      if (this.editFolder.open && typeof this.addOpen === 'function') {
        this.addOpen(this.editFolder.oid)
      }
    },
    delCat(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          fetch('post', '/cpy/cat/delete', {
            oid: row.oid
          }).then((res) => {
            if (res.status === 0) {
              this.queryTree(this.cat.product_category, row.oid, (datas, item, index) => {
                console.log(datas, item, index);
                this.$delete(datas, index);
                this.$refs.edpTree.$forceUpdate()
              })
              this.$message.success('删除成功！')
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
        },
      });
    },
    toInfo(row) {
      // console.log(row);
      this.curTabsIndex = '1'
      this.curProOid = row.node_oid
      this.getProBom(row.node_oid)

      // eslint-disable-next-line no-undef
      console.log(_.add(1, 2, 3, 4));
    },
    toTop() {
      this.curProCatCode = ""
      this.getProList()
    }
  }
}
</script>

<style lang="less" scoped>
.bom-detail {
  /deep/ .ant-table {
    .ant-table-tbody td {
      white-space: nowrap;
      position: relative;

      .edit-name {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 10px 16px;

      }
    }
  }
}

.tabs-bottom-content {
  flex: 1;
  overflow-y: auto;
}

.app-main-content {
  display: flex;

  .edp-card-head-left-title{
    cursor: pointer;
  }

  > .stage-left {
    width: 300px;
    margin-right: 10px;
    flex-shrink: 0;

    .tree-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 16px;
      font-weight: 500;

      .tree-row-info {
        display: flex;
      }

      .tree-row-dropdown {
        padding-right: 5px;
      }
    }

    .dir {
      margin-right: 5px;
      height: 22px;
    }

    .menu-icon .iconfont {
      font-size: 16px;
      font-weight: 500;
    }

    /deep/ .edp-tree-item {
      &.active {
        > .tree-item-row > .tree-row {
          .menu-icon {
            display: block;
          }
        }
      }

      > .tree-item-row {
        .menu-icon {
          display: none;
        }

        &:hover {
          .menu-icon {
            display: block;
          }
        }
      }
    }
  }

  /deep/ .page-card-body {
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;

    // 表格上方的操作栏
    > .body-action {
      display: flex;
      //border-bottom: 1px solid #eee;
      padding-bottom: 10px;

      .right {
        align-items: center;
        display: flex;
        margin-left: auto;

        .item {
          margin-left: 20px;

          .label {
            margin-right: 10px;
          }
        }
      }

      .left {
        .ant-btn {
          background-color: #0088FF;
          color: #FFF;
          border-color: #FFF;
        }
      }
    }

    // 表格和右侧信息的父元素 选择器
    > .body-flex {
      flex: 1;
      height: 100%;
      display: flex;
      overflow: hidden;
      background-color: #F8F8F8;

      // 左侧表格
      > .body-left-table {
        flex: 1;
        height: 100%;
        overflow-x: auto;
        background-color: #fff;
        box-sizing: border-box;
      }

    }
  }

  .edp-card-head-right {
    > i {
      cursor: pointer;
    }
  }
}
</style>
